import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['btn'];
  static values = { buyerLink: String, sellerLink: String }

  connect() {
    const targetElement = document.querySelector('#header-intersection');
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0
    };

    const observer = new IntersectionObserver(this.intersectionObserverCallback, observerOptions);
    if(targetElement) observer.observe(targetElement);
  }

  intersectionObserverCallback = (entries) => {
    this.fixedCTA = document.querySelector('.vesta-fixed-cta')
    const target = entries[0];
    const burgerMenu = document.querySelector('.vesta-mobile-menu-container--active')

    if (!target.isIntersecting && this.fixedCTA == null && burgerMenu == null) {
      this.handleIntersection()
    } else {
      this.handleRevertIntersection()
    }
  };

  handleIntersection = () => {
    const buyerPageFocus = document.querySelector('.vesta-buyer-page-focus') !== null
    const ctaWording = buyerPageFocus ? 'Découvrir nos biens à vendre' : 'Estimer mon bien'
    const ctaElement = `
      <a href=${buyerPageFocus ? this.buyerLinkValue : this.sellerLinkValue} class='vesta-button--primary vesta-fixed-cta vesta-opacity-0' target='_blank' data-gtm='fixed-cta-conversion'>
        ${ctaWording}
        <i class='fas fa-arrow-right fa-lg vesta-margin-l-10' aria-hidden='true'></i>
      </a>
    `
    this.btnTarget.insertAdjacentHTML('afterbegin', ctaElement);
    document.querySelector('.vesta-fixed-cta-container').classList.add('vesta-padding-container')
    // Use Timeout to ensure the element is rendered in the DOM before applying opacity change
    setTimeout(() => {
      document.querySelector('.vesta-fixed-cta').classList.remove('vesta-opacity-0');
      document.querySelector('.vesta-fixed-cta').classList.add('vesta-opacity-1');
    }, 200);
  }

  handleRevertIntersection = () => {
    this.btnTarget.innerHTML = ''
    document.querySelector('.vesta-fixed-cta-container').classList.remove('vesta-padding-container')
  }
}
