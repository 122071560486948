const gtmEvents = () => {
  // Push events on page load
  const buyerAlertSuccess = document.getElementById('gtm-buyer-alert-success');

  if (buyerAlertSuccess) {
    let eventDetails = gtmEventsList['buyer-alert-submit']
    pushInDataLayer(eventDetails);
  }

  window.onclick = function (e) {
    // get the clicked element link, input or custom element with gtm-tracked class
    let gtmTagName = e.target.closest('a')
    gtmTagName = gtmTagName || e.target.closest('input')
    gtmTagName = gtmTagName || e.target.closest('.gtm-tracked')
    // General trigger
    if (gtmTagName && gtmTagName.dataset.gtm) {
      let eventDetails = gtmEventsList[gtmTagName.dataset.gtm]
      if (eventDetails) {
        pushInDataLayer(eventDetails);
      }
    }

    //  Special cases:
    //  - Blog articles inline links to estimation and generic /estimation/estimation-kind unless a gtml trigger is present on the body article
    if (window.location.pathname.includes('/blog/') && !window.location.pathname.includes('/admin/') && e.target.closest('a')) {
      const link = e.target.closest('a')
      if (link.href.endsWith('/estimation') && link.href.includes('app.') && !e.target.dataset.gtm) {
        let eventDetails = gtmEventsList['blog-article-inline']
        pushInDataLayer(eventDetails);
      }
    }

    // Fees Simulator
    const trigger = e.target.closest('.noUi-handle')

    if (trigger && window.location.pathname == '/vendre') {
      let eventDetails = gtmEventsList['fees-simulator']
      eventDetails.value = trigger.getAttribute('aria-valuetext')
      if (eventDetails.value > 100) {
        pushInDataLayer(eventDetails);
      }
    }

  }
}

const pushInDataLayer = (eventDetails) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'GAEvent',
    'eventCategory': eventDetails.category || undefined,
    'eventAction': eventDetails.action || undefined,
    'eventLabel': eventDetails.label || undefined,
    'eventValue': eventDetails.value || undefined
  });
}

// All gtm events data
const gtmEventsList = {
  'top-menu-login': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: `Top Menu - Connexion - ${window.location.pathname}`
  },
  'top-menu-l2-buyer-alert': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: `Top Menu - Level 2 - Alerte Achat - ${window.location.pathname}`
  },
  'top-menu-l2-adverts': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: `Top Menu - Level 2 - Annonces - ${window.location.pathname}`
  },
  'top-menu-l2-blog': {
    category: 'Site - Static Pages',
    action: 'Click',
    label: `Top Menu - Level 2 - Blog - ${window.location.pathname}`
  },
  'top-menu-l2-experts': {
    category: 'Site - Static Pages',
    action: 'Click',
    label: `Top Menu - Level 2 - Experts - ${window.location.pathname}`
  },
  'top-menu-l2-buyer-guide': {
    category: 'Site - Static Pages',
    action: 'Click',
    label: `Top Menu - Level 2 - Guide Acheteur - ${window.location.pathname}`
  },
  'top-menu-l2-owner-guide': {
    category: 'Site - Static Pages',
    action: 'Click',
    label: `Top Menu - Level 2 - Guide Proprietaire - ${window.location.pathname}`
  },
  'top-menu-l2-seller-guide': {
    category: 'Site - Static Pages',
    action: 'Click',
    label: `Top Menu - Level 2 - Guide Vendeur - ${window.location.pathname}`
  },
  'top-menu-l2-buyer-offer': {
    category: 'Site - Static Pages',
    action: 'Click',
    label: `Top Menu - Level 2 - Offre Acheteur - ${window.location.pathname}`
  },
  'top-menu-l2-seller-offer': {
    category: 'Site - Static Pages',
    action: 'Click',
    label: `Top Menu - Level 2 - Offre Vendeur - ${window.location.pathname}`
  },
  'top-menu-l2-premium': {
    category: 'Site - Static Pages',
    action: 'Click',
    label: `Top Menu - Level 2 - Premium - ${window.location.pathname}`
  },
  'top-menu-logo': {
    category: 'Site - Static Pages',
    action: 'Click',
    label: `Top Menu - Logo Hosman - ${window.location.pathname}`
  },
  'top-menu-sell': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: `Top Menu - Vendre - ${window.location.pathname}`
  },
  'top-menu-call': {
    category: 'Site - Static Pages',
    action: 'Click',
    label: `Top Menu - Call - ${window.location.pathname}`
  },
  'acheter-primary-request-button-buy': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Acheter - Primary - Annonces'
  },
  'buy-secondary-adverts': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Acheter - Secondary - Annonces'
  },
  'buy-tertiary-adverts': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Acheter - Tertiary - Annonces'
  },
  'buy-tertiary-buyer-alert': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Acheter - Tertiary - Alerte Achat'
  },
  'buy-quaternary-buyer-alert': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Acheter - Quaternary - Alerte Achat'
  },
  'vendre-primary-request-button-estimate': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Vendre - Primary - Option Estimer'
  },
  'vendre-primary-request-button-sell': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Vendre - Primary - Option Vendre'
  },
  'sell-primary-adverts': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Vendre - Primary - Annonces'
  },
  'sell-secondary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Vendre - Secondary'
  },
  'sell-estimation-commission': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Vendre - Tertiary'
  },
  'sell-quaternary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Vendre - Quaternary'
  },
  'sell-simulator-cursor': {
    category: 'Site - Static Pages',
    action: 'Click',
    label: 'Vendre - Simulator cursor move'
  },
  'adverts-buyer-alert': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Nos annonces - Alerte achat'
  },
  'filters-open-budget': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Open Budget'
  },
  'filters-open-location': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Open Location'
  },
  'filters-open-moreCriteria': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Open More Criteria'
  },
  'filters-open-type': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Open Property Type'
  },
  'filters-open-rooms': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Open Rooms'
  },
  'filters-open-surface': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Open Surface'
  },
  'filters-submit-budget': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Submit Budget'
  },
  'filters-submit-location': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Submit Location'
  },
  'filters-submit-moreCriteria': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Submit More Criteria'
  },
  'filters-submit-type': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Submit Property Type'
  },
  'filters-submit-rooms': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Submit Rooms'
  },
  'filters-submit-surface': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Submit Surface'
  },
  'open-mobile-filters': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Open All Mobile'
  },
  'submit-mobile-filters': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Submit All Mobile'
  },
  'fetch-sold-properties': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Sold properties'
  },
  'fetch-sold-properties': {
    category: 'Site - Advert Listing',
    action: 'Click',
    label: 'Filters - Sold properties'
  },
  'footer-estimation': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: `Footer - Estimation - ${window.location.pathname}`
  },
  'expert-primary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Expert - Primary'
  },
  'expert-secondary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Expert - Secondary'
  },
  'expert-secondary-adverts': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Expert - Secondary - Annonces'
  },
  'expert-tertiary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Expert - Tertiary'
  },
  'homepage-primary-request-button-buy': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Homepage - Primary - Option Acheter'
  },
  'homepage-primary-request-button-estimate': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Homepage - Primary - Option Estimer'
  },
  'homepage-primary-request-button-sell': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Homepage - Primary - Option Vendre'
  },
  'homepage-secondary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Homepage - Secondary'
  },
  'homepage-secondary-adverts': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Homepage - Secondary - Annonces'
  },
  'homepage-secondary-buyer-alert': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Homepage - Secondary - Alerte Achat'
  },
  'homepage-tertiary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Homepage - Tertiary'
  },
  'homepage-quaternary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Homepage - Quaternary'
  },
  'blog-adverts': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Blog Article - Annonces'
  },
  'advert-estimation': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Advert Page - Estimation'
  },
  'main-side-alert': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Advert Page - Primary Alerte Achat'
  },
  'advert-secondary-buyer-alert': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Advert Page - Secondary - Alerte Achat'
  },
  'advert-tertiary-adverts': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Advert Page - Tertiary - Annonces'
  },
  'main-book-visit': {
    category: 'Site - Advert Page',
    action: 'Click',
    label: `Book visit - ${window.location.pathname}`
  },
  'buyer-alert-submit': {
    category: 'Site - Static Pages',
    action: 'Submit',
    label: 'Buyer Alert'
  },
  'estimation-buyer-alert-success': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Estimation Buyer Alert Success'
  },
  'zones-primary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Zones - Primary'
  },
  'zones-primary-adverts': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Zones - Primary - Annonces'
  },
  'zones-secondary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Zones - Secondary'
  },
  'advert-phone-expert-reveal': {
    category: 'Site - Advert Page',
    action: 'Click',
    label: `Reveal Phone expert - ${window.location.pathname}`
  },
  'landing-locale-primary-request-button-estimate': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: `Local Page - Primary - Option Estimer - ${window.location.pathname}`
  },
  'landing-locale-primary-request-button-sell': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: `Local Page - Primary - Option Vendre - ${window.location.pathname}`
  },
  'landing-locale-secondary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: `Local Page - Secondary - ${window.location.pathname}`
  },
  'landing-locale-tertiary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: `Local Page - Tertiary - ${window.location.pathname}`
  },
  'estimation-primary-request-button-estimate': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Landing Estimation - Primary'
  },
  'landing-estimation-secondary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Landing Estimation - Secondary'
  },
  'landing-estimation-tertiary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Landing Estimation - Tertiary'
  },
  'premium-primary-request-button-estimate': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Landing Premium - Primary - Option Estimer'
  },
  'premium-primary-request-button-sell': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Landing Premium - Primary - Option Vendre'
  },
  'premium-primary-request-button-buy': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Landing Premium - Primary - Option Acheter'
  },
  'premium-secondary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Landing Premium - Secondary'
  },
  'premium-tertiary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Landing Premium - Tertiary'
  },
  'guide-du-vendeur-primary': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: 'Seller Guide - Primary'
  },
  'guide-de-l-acheteur-primary': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Guide Acheteur Home - Annonces'
  },
  'guides-adverts': {
    category: 'Buyer Page - CTA',
    action: 'Click',
    label: 'Guide Article - Annonces'
  },
  'fees-simulator': {
    category: 'Site - Static Pages',
    action: 'Click',
    label: 'Vendre - Simulator cursor move'
  },
  'reveal-phone-number': {
    category: 'Site - Advert Page',
    action: 'Click',
    label: `Reveal Phone expert - ${window.location.pathname}`
  },
  'visit-form-submit': {
    category: 'Site - Advert Page',
    action: 'Submit',
    label: 'Visit Form'
  },
  'blog-article-inline': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: `Blog Article - Inline - ${window.location.pathname}`
  },
  'estimate-henriette-module': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: `Henriette - Module - ${window.location.pathname}`
  },
  'estimate-henriette-page': {
    category: 'Conversion Page - CTA',
    action: 'Click',
    label: `Henriette - Page - ${window.location.pathname}`
  },
};

export default gtmEvents;
