import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['burgerMenu', 'navbarContainer'];

  toggle = () => {
    const elementHiddenForNav = document.querySelectorAll('.vesta-hide-for-nav');
    if (elementHiddenForNav.length > 0) {
      elementHiddenForNav.forEach(el => {
        el.classList.remove('vesta-display-none');
        el.classList.remove('vesta-hide-for-nav');
      })
    } else {
      const fixedCTA = document.querySelector('.vesta-fixed-cta')
      if (fixedCTA) fixedCTA.remove()
      // Get next element after navbar that should be the main page's container scoped with id
      const nextEl = this.navbarContainerTarget.nextElementSibling;
      const footer = document.querySelector('#myFooter');
      if(nextEl.hasAttribute('id')) {
        nextEl.classList.add('vesta-display-none');
        nextEl.classList.add('vesta-hide-for-nav');
      }
      if(footer) {
        footer.classList.add('vesta-display-none');
        footer.classList.add('vesta-hide-for-nav');
      }
    }
    $('html, body').animate({ scrollTop: 0 }, 500);

    this.burgerMenuTarget.classList.toggle('vesta-mobile-menu-container--active');
  };
}
