import { Controller } from '@hotwired/stimulus';
import { buyerAlertsMatch } from '../../api/buyerAlerts';

export default class extends Controller {
  static targets = ['container', 'leadsEllipsesContainer', 'leadsCount', 'modal', 'modall', 'cardsContainer', 'leadsCountMinusDisplayed']

  static values = {
    saleUniqueHash: String,
    salePrice: String,
    zoneCategoryId: String,
    regionNamePretty: String,
    activeZone: Boolean
  }

  connect = async () => {
    const { buyer_alerts, count } = await buyerAlertsMatch(this.saleUniqueHashValue, this.salePriceValue)
    this.checkConditionDisplay(count)
    if(!this.requestedConditionDisplay) return

    this.containerTarget.classList.remove('vesta-display-none')
    await buyer_alerts.forEach(buyer_alert => {
      const initials = `${buyer_alert.user.first_name[0]}${buyer_alert.user.last_name_initial}`
      this.leadsEllipsesContainerTarget.insertAdjacentHTML('beforeEnd', this.ellipseHTML(initials))

      let buyerAlertMatchCard = `
        <div class='vesta-card-lead-buyer-alert'>
          <div class='vesta-card-lead-buyer-alert--header vesta-text-center'>
            <div class='typo-h4'>${buyer_alert.user.first_name} ${buyer_alert.user.last_name_initial}.</div>
          </div>

          <div class='flex-col vesta-margin-b-10 text-left vesta-padding-15 vesta-card-lead-buyer-alert--content'>
            <div class='typo-content vesta-indigo-40'>Budget</div>

            <div class='typo-bold-content vesta-margin-b-10'>${this.numberToCurrency(buyer_alert.min_budget)} - ${this.numberToCurrency(buyer_alert.max_budget)}</div>

            <div class='typo-content vesta-indigo-40'>À la recherche de</div>

            <div class='vesta-margin-b-10'>
              ${buyer_alert.apartment ? "<div class='typo-bold-content'><i class='fa-duotone fa-apartment'></i> Appartement</div>" : ''}
              ${buyer_alert.house ? "<div class='typo-bold-content'><i class='fa-duotone fa-house'></i> Maison</div>" : ''}
            </div>

            <div class='typo-content vesta-indigo-40'>Surface</div>

            <div class='typo-bold-content'> > à ${(Math.floor(buyer_alert.min_area))}m2 / ${buyer_alert.min_room_number == 0 || buyer_alert.min_room_number == 1 ? `Studio et +` : `${buyer_alert.min_room_number} pièces et +`}</div>
          </div>
        </div>
      `

      this.cardsContainerTarget.insertAdjacentHTML('beforeEnd', buyerAlertMatchCard)
    });
    this.leadsEllipsesContainerTarget.insertAdjacentHTML('beforeEnd', this.ellipseHTML(`+${count - 3}`))
    this.leadsCountTargets.forEach(leadCount => leadCount.innerHTML = count)
    this.leadsCountMinusDisplayedTarget.innerHTML = count - 3
  }

  checkConditionDisplay = (count) => {
    const ParisZone1AboveThreeHundred = count > 300 && this.zoneCategoryIdValue == 1 && this.regionNamePrettyValue == 'Région Parisienne'
    const ParisOtherZonesAboveTwoHundred = count > 200 && this.zoneCategoryIdValue != 1 && this.regionNamePrettyValue == 'Région Parisienne'
    const RegionAboveOneHundred = (count > 100 && this.regionNamePrettyValue != 'Région Parisienne')
    this.requestedConditionDisplay = this.activeZoneValue === true && ( ParisZone1AboveThreeHundred || ParisOtherZonesAboveTwoHundred || RegionAboveOneHundred)
  }

  ellipseHTML = (value) => {
    return `
      <div class='vesta-ellipse vesta-margin-r-8'>
        <span class='typo-h4'>${value}</span>
      </div>
    `
  }

  numberToCurrency = (number) => {
    return number.toLocaleString('fr-FR', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0  });
  }
}
