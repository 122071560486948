import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ 'input', 'pacContainer' ]
  static values = { buyPage: Boolean, hosmanian: Boolean}

  connect() {
    this.estimationKind = this.buyPageValue === true ? 'buy' : 'online';
    this.inputTarget.placeholder = this.estimationKind === 'buy' ? 'Entrez une ville, un arrondissement...' : 'Adresse du bien à estimer...';

    const placesOptions = {
      types: ['geocode'],
      componentRestrictions: { country: 'fr' },
      fields: ['address_components','formatted_address','geometry.location']
    };
    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, placesOptions);
    this.autocomplete.addListener('place_changed', () => this.onPlaceChanged());
    this.waitForPacContainer();
  }

  waitForPacContainer() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.classList && node.classList.contains('pac-container')) {
            const pacContainers = document.querySelectorAll('.pac-container');
            // If multiple input don't move the container (in mutation observer no way to make diffence between multiple pac-container added in the DOM)
            if (pacContainers.length === 1) this.moveAutocompleteContainer(node);
            observer.disconnect();
          }
        });
      });
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  }

  moveAutocompleteContainer(node) {
    node.classList.add('vesta-pac-container');
    
    if (node && this.hasPacContainerTarget) this.pacContainerTarget.insertAdjacentElement('afterend', node);
  }

  onPlaceChanged = () => {
    this.place = this.autocomplete.getPlace();
    this.handlePlaceAsCookie();
    this.redirectTo();
  }

  handlePlaceAsCookie = () => {
    if (this.validePlaceData()) this.saveAsCookie(this.place, 'googlePlaceData');
  }

  redirectTo = () => {
    if (this.buyPageValue === true) this.listingAdsUrl()
    else this.funnelUrl()
  }

  saveAsCookie = (data, cookieName) => {
    if (typeof data === 'object') data = JSON.stringify(data);
    document.cookie = `${cookieName}=${data}; Domain=.lvh.me; path=/`;
  }

  validePlaceData = () => {
    return this.place?.geometry?.location && this.place?.formatted_address && this.place?.address_components
  }

  listingAdsUrl = () => {
    if (this.place?.address_components) {
      const addressComponents = this.place.address_components;
      const postalCodeComponent = addressComponents.filter(addressComponent => addressComponent.types.includes('postal_code'))[0]
      const cityComponent = addressComponents.filter(addressComponent => addressComponent.types.includes('locality'))[0]
      const postalCode = postalCodeComponent.short_name;
      const city = cityComponent.short_name;
      window.location = `/annonces?zip_code=${postalCode}&city=${city}`;
    } else window.location = `/annonces`
  }

  funnelUrl = () => {
    let fullPath;

    if (this.hosmanianValue) {
      fullPath = window.location.origin.replace(window.location.host, `admin.${window.location.host.replace('www.', '')}/nouveau-lead`)
    } else {
      fullPath = window.location.origin.replace(window.location.host, `app.${window.location.host.replace('www.', '')}/estimation`)
    }

    if (this.validePlaceData()) {
      this.dataToParams()
      window.location = `${fullPath}?${this.params}`;
    } else window.location = `${fullPath}`;
  }

  dataToParams = () => {
    this.params = new URLSearchParams();
    this.params.append('formatted_address', this.place.formatted_address);
    this.params.append('lat', this.place.geometry.location.lat());
    this.params.append('lng', this.place.geometry.location.lng());
    for(let key in this.place.address_components) {
      const addressComponent = this.place.address_components[key];
      if (addressComponent.types.includes('street_number')) this.params.append('street_number', addressComponent['long_name'])
      else if (addressComponent.types.includes('route')) this.params.append('street_name', addressComponent['long_name'])
      else if (addressComponent.types.includes('postal_code')) this.params.append('zip_code', addressComponent['long_name'])
      else if (addressComponent.types.includes('locality')) this.params.append('city', addressComponent['long_name'])
      else if (addressComponent.types.includes('country')) this.params.append('country_code', addressComponent['short_name'])
    }
  }
}
