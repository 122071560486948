import { Controller } from '@hotwired/stimulus';
import { agencyFees } from '../../../shared/helpers/agencyFeesHelper';

export default class extends Controller {
  static targets = ['agencyFees',
                    'netSellerWithClassicAgency',
                    'netSellerWithHosman',
                    'agencyFeesDiff',
                    'premiumLogo',
                    'priceRef',
                    'firstCategory',
                    'secondCategory',
                    'thirdCategory',
                    'fourthCategory',
                    'container'];

  static values = {
    parisRegion: String,
    houseKind: Boolean,
    price: String
  }

  connect = async () => {
    this.handleAgencyFees()
    this.handleHTMLContent()
    this.handleHTMLDisplay()
  }

  handleAgencyFees = () => {
    this.agencyFees = agencyFees(
      this.parisRegionValue,
      this.houseKindValue,
      this.priceValue,
      this.noExclusivityValue
    );
  }

  handleHTMLContent = () => {
    this.feesWithClassicAgency = Number(this.priceValue) * (4.87 / 100)
    this.agencyFeesTarget.innerHTML = this.formatPriceHelper(this.agencyFees)
    this.netSellerWithHosmanTarget.innerHTML = this.formatPriceHelper(Number(this.priceValue) - Number(this.agencyFees))
    this.netSellerWithClassicAgencyTarget.innerHTML = this.formatPriceHelper(Number(this.priceValue) - Number(this.feesWithClassicAgency))
    if((this.feesWithClassicAgency - this.agencyFees) < 1000) this.containerTarget.classList.add('vesta-display-none')
    this.agencyFeesDiffTarget.innerHTML = this.formatPriceHelper(this.feesWithClassicAgency - this.agencyFees)
    this.priceRefTarget.innerHTML = this.formatPriceHelper(Number(this.priceValue))
  }

  handleHTMLDisplay = () => {
    // Premium logo condition
    if ((Number(this.price) >= 800000 && !this.parisRegion) || (Number(this.price) >= 900000 && this.parisRegion)) this.premiumLogoTarget.classList.toggle('vesta-display-none')

    // "Strong points" trad condition according to hosman prices steps
    if (Number(this.price) <= 300000) this.firstCategoryTarget.classList.toggle('vesta-display-none')
    else if (Number(this.price) <= 500000) this.secondCategoryTarget.classList.toggle('vesta-display-none')
    else if (Number(this.price) <= 900000) this.thirdCategoryTarget.classList.toggle('vesta-display-none')
    else this.fourthCategoryTarget.classList.toggle('vesta-display-none')
  }


  formatPriceHelper = (price) => {
    const options = { style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0 };
    const priceString = price.toLocaleString('fr-FR', options);
    return priceString;
  }
}
