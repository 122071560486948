import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    userId: Number,
    userRole: String
  }

  connect() {
    // new GA4 tracking plan
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'login',
      'user_kind': this.userRoleValue,
      'user_id': this.userIdValue
    })
  }
}
