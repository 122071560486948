import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    mandate: String,
  }

  connect() {
    this.listenIframe(this.mandateValue);
  }

  listenIframe(mandate_id) {
    window.addEventListener('message', function(e) {
      if (e.origin === 'https://yousign.app' && e.data.type === 'yousign') {
        if (e.data.event === 'success') window.location.href = `/after_esign/${mandate_id}?result=success`;
        if (e.data.event === 'error') window.location.href = `/after_esign/${mandate_id}?result=error`
      }
    });
  }
}
